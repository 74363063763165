import { useEffect, useState, useRef } from 'react';
import { InstagramEmbed } from 'react-social-media-embed';
import { makeStyles } from '@mui/styles';

// Material UI
import {
    Box,
    Button,
    Container,
    Dialog,
    Fade,
    MenuItem,
    Grid,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
    Tabs,
    Tab,
} from '@mui/material';
import TranslationInfoCard from '@mui/material/Alert';
import {
    CopyAll,
    ArrowBackIos,
    ArrowForwardIos,
    Edit,
    Autorenew,
    Done as DoneIcon,
} from '@mui/icons-material';
import PropTypes from 'prop-types';

// Custom components
import Notification from '../../components/Notification';
import AppFrame from '../../hoc/AppFrame';
import LanguageSelector from './LanguageSelector';

// Services
import {
    getCommentRecommendation,
    getPostsToComment,
    getPostToCommentCountsOfBrand,
    markPostAsIgnored,
    markPostAsCommented,
} from '../../services';
import useAuthorization from '../../hooks/useAuthorization';
import roles from '../../hooks/roles';

const useStyles = makeStyles({
    customSelect: {
        padding: '16px',
        backgroundColor: '#f5f5f5',
    },
});

// Container component
const CommentInstagramTool = () => {
    useAuthorization([roles.BRAND]);

    const isMounted = useRef(false);
    const [commentTypeOptions, setCommentTypeOptions] = useState([]);
    const [defaultPostCountDict, setDefaultPostCountDict] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [hideCommentTool, setHideCommentTool] = useState(true);
    const [chosenCommentType, setChosenCommentType] = useState(commentTypeOptions[0]);
    const [fullPostCountDict, setFullPostCountDict] = useState(defaultPostCountDict);

    const updatePostCountOfType = (type, newCount) => {};

    useEffect(() => {
        const firstLoadSetup = async () => {
            const cTypeCounts = await getPostToCommentCountsOfBrand();
            const cTypeOptionsFromDb = cTypeCounts.data;
            const defaultPostCountDict = cTypeOptionsFromDb.reduce((acc, cType) => {
                acc[cType.type] = cType.count;
                return acc;
            }, {});

            // set chosen type default
            const firstNotZeroCountType = cTypeOptionsFromDb.find((cType) => cType.count > 0);
            if (firstNotZeroCountType) {
                setChosenCommentType(firstNotZeroCountType);
                setHideCommentTool(false);
            } else {
                setHideCommentTool(true);
            }

            setCommentTypeOptions(cTypeOptionsFromDb);
            setFullPostCountDict(defaultPostCountDict);
            setIsLoading(false);
        };

        firstLoadSetup();
    }, []);

    return (
        <Container sx={{ marginTop: '12px' }}>
            {!isLoading && (
                <>
                    {hideCommentTool ? (
                        <>
                            <NoPostsAtAll />
                        </>
                    ) : fullPostCountDict[chosenCommentType.type] === 0 ? (
                        <>
                            <NoPostsForCommentType
                                commentTypeOptions={commentTypeOptions}
                                fullPostCountDict={fullPostCountDict}
                                setChosenCommentType={setChosenCommentType}
                            />
                        </>
                    ) : (
                        <>
                            <CommentToolInterface
                                updatePostCountOfType={updatePostCountOfType}
                                fullPostCountDict={fullPostCountDict}
                                commentTypeOptions={commentTypeOptions}
                                chosenCommentType={chosenCommentType}
                                setChosenCommentType={setChosenCommentType}
                            />
                        </>
                    )}
                </>
            )}
        </Container>
    );
};

// Comment tool
const CommentToolInterface = ({
    updatePostCountOfType,
    fullPostCountDict,
    commentTypeOptions,
    chosenCommentType,
    setChosenCommentType,
}) => {
    // Comment and Post
    const [postToComment, setPostToComment] = useState('');
    const [postToCommentUrl, setPostToCommentUrl] = useState('');
    const [commentText, setCommentText] = useState('');
    const [commentTranslation, setCommentTranslation] = useState('');
    const [postToCommentIndex, setPostToCommentIndex] = useState(0);
    const [language, setLanguage] = useState('auto');
    // Edit
    const [edit, setEdit] = useState(false);
    const [wasCommentEdited, setWasCommentEdited] = useState(false);
    // Dialogs and Notifications
    const [showNotification, setShowNotification] = useState(false);
    const [showIgnoreDialog, setShowIgnoreDialog] = useState(false);
    const [showNextAlertDialog, setShowNextAlertDialog] = useState(false);
    const [showPrevAlertDialog, setShowPrevAlertDialog] = useState(false);
    const [showNewAlertDialog, setShowNewAlertDialog] = useState(false);

    const refreshPost = async () => {
        if (postToComment) {
            const url = `https://www.instagram.com/p/${postToComment.shortcode}`;
            setPostToCommentUrl(url);
            await fetchCommentRecommendation();
        }
    };

    // fetchers
    const fetchPreviousPostToComment = async () => {
        const newPreviousPostCount = postToCommentIndex - 1;
        const postToCommentResponse = await getPostsToComment(
            chosenCommentType.type,
            newPreviousPostCount
        );
        const postToCommentFromDb = postToCommentResponse.data;

        setPostToCommentIndex(newPreviousPostCount);
        setPostToComment(postToCommentFromDb);
        setWasCommentEdited(false);
    };
    const fetchNextPostToComment = async () => {
        const newNextPostCount = postToCommentIndex + 1;
        setPostToCommentIndex(newNextPostCount);

        const postToCommentResponse = await getPostsToComment(
            chosenCommentType.type,
            newNextPostCount
        );
        const postToCommentFromDb = postToCommentResponse.data;

        setPostToComment(postToCommentFromDb);
        setWasCommentEdited(false);
    };

    async function fetchNextPostWithoutCounterIncrement() {
        const postToCommentResponse = await getPostsToComment(
            chosenCommentType.type,
            postToCommentIndex
        );
        const postToCommentFromDb = postToCommentResponse.data;
        setPostToComment(postToCommentFromDb);
    }
    const fetchCommentRecommendation = async () => {
        setCommentText('Loading...');

        const newRecommendationResponse = await getCommentRecommendation(
            postToComment.mediaToCaption,
            postToComment.label,
            language
        );
        if (
            newRecommendationResponse &&
            newRecommendationResponse.data &&
            newRecommendationResponse.data.commentRecommendation
        ) {
            const { commentRecommendation, translatedComment } = newRecommendationResponse.data;
            setCommentText(commentRecommendation);
            setCommentTranslation(translatedComment);
        }
        setWasCommentEdited(false);
    };

    // handlers
    const handleCopyClick = () => {
        console.log(commentText[0]);
        navigator.clipboard.writeText(commentText);
        // copy to clipboard by replacing /n with %0A
        // navigator.clipboard.writeText(
        //     commentRecommendation.replace(/\n/g, '%0A')
        // );

        setShowNotification(true);

        setTimeout(() => {
            setShowNotification(false);
        }, 3000);
    };
    const handleNextClick = async () => {
        if (wasCommentEdited) {
            setShowNextAlertDialog(true);
        } else {
            await fetchNextPostToComment();
        }
    };
    const handlePreviousClick = async () => {
        if (wasCommentEdited) {
            setShowPrevAlertDialog(true);
        } else {
            await fetchPreviousPostToComment();
        }
    };
    const handleNewClick = async () => {
        if (wasCommentEdited) {
            setShowNewAlertDialog(true);
        } else {
            await fetchCommentRecommendation();
        }
    };
    const handleSubmitComment = async () => {
        try {
            await markPostAsCommented(postToComment._id);
            await fetchNextPostWithoutCounterIncrement();

            await decrementFullPostCount();
        } catch (err) {
            console.log('marking service not available');
        }
    };
    const decrementFullPostCount = async () => {
        // decrement with 1 for the chosen type
        updatePostCountOfType(
            chosenCommentType.type,
            fullPostCountDict[chosenCommentType.type] - 1
        );
    };
    const handleLanguageChange = async (language) => {
        setLanguage(language);
    };

    useEffect(() => {
        // if there is zero or one post only, disable next button
        if (fullPostCountDict[chosenCommentType.type] <= 1) {
            setPostToCommentIndex(0);
        }
    }, []);

    useEffect(() => {
        refreshPost();
    }, [postToComment]);

    useEffect(() => {
        async function fetch() {
            setPostToCommentIndex(0);
            await fetchNextPostWithoutCounterIncrement();
        }
        fetch();
    }, [chosenCommentType]);

    useEffect(() => {
        async function fetchAfterLangChange() {
            await fetchNextPostWithoutCounterIncrement();
        }
        fetchAfterLangChange();
    }, [language]);

    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '72px',
            }}
        >
            <Notification
                open={showNotification}
                message="Copied to clipboard"
                onClose={() => setShowNotification(false)}
                // place to top right
            />

            <Grid container spacing={3}>
                {/* Instagram Post Block */}
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={8}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Stack direction={'column'} width={'100%'}>
                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                            paddingX={0}
                            paddingY={1}
                            borderRadius={4}
                            marginBottom={2}
                            height="60px"
                            width="100%"
                        >
                            {/* select comment type */}
                            <Stack
                                direction="row"
                                spacing={2}
                                justifyContent="space-between"
                                paddingX={0}
                                paddingY={0}
                                borderRadius={4}
                                height="60px"
                                width="35%"
                            >
                                <CommentTypeSelector
                                    commentTypeOptionObjectList={commentTypeOptions}
                                    commentDict={fullPostCountDict}
                                    chosenCommentType={chosenCommentType}
                                    setChosenCommentType={setChosenCommentType}
                                />
                            </Stack>
                            {/* comment: previous next ignore */}
                            <Stack
                                direction="row"
                                spacing={2}
                                justifyContent="space-between"
                                paddingX={2}
                                paddingY={1}
                                borderRadius={4}
                                backgroundColor="#f5f5f5"
                                height="60px"
                                width="65%"
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<ArrowBackIos />}
                                    onClick={async () => {
                                        await handlePreviousClick();
                                    }}
                                    disabled={postToCommentIndex < 1 || edit}
                                >
                                    Previous
                                </Button>

                                <Box
                                    backgroundColor="white"
                                    borderRadius={4}
                                    width="100px"
                                    padding="8px"
                                    // center
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Typography>
                                        {/* comment count index and full count */}
                                        {`${postToCommentIndex + 1} / ${
                                            fullPostCountDict[chosenCommentType.type]
                                        }`}
                                    </Typography>
                                </Box>

                                {/* Next post button */}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    endIcon={<ArrowForwardIos />}
                                    onClick={async () => {
                                        await handleNextClick();
                                    }}
                                    disabled={
                                        postToCommentIndex >=
                                            fullPostCountDict[chosenCommentType.type] - 1 ||
                                        fullPostCountDict[chosenCommentType.type] <= 1 ||
                                        edit
                                    }
                                >
                                    Next
                                </Button>
                            </Stack>
                        </Stack>

                        {/* instagram post */}
                        <Grid
                            container
                            sx={{
                                width: '100%',
                                borderRadius: '8px',
                                padding: '16px',
                                backgroundColor: '#f5f5f5',
                            }}
                        >
                            {/* post text */}
                            <Grid item xs={12} sm={12} md={4} lg={6} xl={6}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '90%',
                                        backgroundColor: 'white',
                                        borderRadius: '8px',
                                        paddingX: '16px',
                                        verticalAlign: 'middle',
                                        alignItems: 'center',
                                        marginBottom: '16px',
                                        height: '40px',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    {/* title with date  */}
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            display: {
                                                xs: 'none',
                                                sm: 'none',
                                                md: 'none',
                                                lg: 'block',
                                                xl: 'block',
                                            },
                                        }}
                                    >
                                        {`Published: `}
                                    </Typography>
                                    <Typography variant="body2">
                                        {new Date(postToComment.publishedAt).toLocaleDateString(
                                            'en-US',
                                            {
                                                // if this year, hide year, otherwise show
                                                year:
                                                    new Date(
                                                        postToComment.publishedAt
                                                    ).getFullYear() === new Date().getFullYear()
                                                        ? undefined
                                                        : 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: false, // Use 24-hour time format
                                            }
                                        )}
                                    </Typography>
                                </Box>
                                <CaptionTranslationTabs postToComment={postToComment} />
                            </Grid>

                            {/* iframe for embeded post */}
                            <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        width: '100%',
                                        marginBottom: '16px',
                                    }}
                                    onClick={() => {
                                        window.open(
                                            `https://www.instagram.com/p/${postToComment.shortcode}`,
                                            '_blank'
                                        );
                                    }}
                                >
                                    Open post
                                </Button>
                                {postToCommentUrl && (
                                    <InstagramEmbed
                                        url={postToCommentUrl}
                                        key={postToComment.shortcode}
                                        width="100%"
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Stack>
                </Grid>

                {/* recommended Comment block */}
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Stack direction={'column'}>
                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                            paddingX={0}
                            paddingY={1}
                            borderRadius={4}
                            marginBottom={2}
                            backgroundColor="#f5f5f5"
                            height="60px"
                        >
                            <Stack
                                direction="row"
                                spacing={2}
                                justifyContent="space-between"
                                paddingX={2}
                                paddingY={1}
                                borderRadius={4}
                                backgroundColor="#f5f5f5"
                                height="60px"
                                width="100%"
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<Autorenew />}
                                    sx={{
                                        width: '33%',
                                    }}
                                    onClick={() => {
                                        handleNewClick();
                                    }}
                                    disabled={edit}
                                >
                                    New
                                </Button>

                                {edit ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<Edit />}
                                        onClick={() => {
                                            setEdit(!edit);
                                            setWasCommentEdited(true);
                                        }}
                                        sx={{
                                            width: '33%',
                                        }}
                                    >
                                        Done
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<Edit />}
                                        sx={{
                                            width: '33%',
                                        }}
                                        onClick={() => {
                                            setEdit(!edit);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                )}

                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<CopyAll />}
                                    sx={{
                                        width: '33%',
                                    }}
                                    onClick={() => {
                                        handleCopyClick();
                                    }}
                                    disabled={edit}
                                >
                                    Copy
                                </Button>
                            </Stack>
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                            paddingX={2}
                            paddingY={2}
                            sx={{ marginBottom: '24px' }}
                        >
                            {/* Ignore this post button */}
                            <Button
                                variant="contained"
                                color="primary"
                                // startIcon={<ArrowForwardIos />}
                                onClick={() => {
                                    setShowIgnoreDialog(true);
                                }}
                                disabled={edit}
                                sx={{
                                    width: '30%',
                                }}
                            >
                                Ignore
                            </Button>
                            {/* Comment this post button */}
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={edit}
                                onClick={handleSubmitComment}
                                startIcon={<DoneIcon />}
                                sx={{
                                    width: '70%',
                                }}
                            >
                                commented
                            </Button>
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="space-between"
                            paddingX={2}
                            paddingY={2}
                            sx={{ marginBottom: '24px' }}
                            marginX={0}
                        >
                            <LanguageSelector handleLanguageChange={handleLanguageChange} />
                        </Stack>
                        {edit ? (
                            <TextField
                                value={commentText}
                                onChange={(e) => setCommentText(e.target.value)}
                                multiline
                                rows={20}
                                fullWidth
                            />
                        ) : (
                            <>
                                <Typography variant="h4" component="h1" gutterBottom>
                                    Your comment:
                                </Typography>
                                <Typography paddingBottom={5}>
                                    {/* comment rec */}
                                    {commentText}
                                </Typography>
                                {commentText !== 'Loading...' &&
                                    commentText !== commentTranslation && (
                                            <TranslationInfoCard severity="info">
                                                <Typography
                                                    variant="h4"
                                                    component="h1"
                                                    gutterBottom
                                                >
                                                    Translation:
                                                </Typography>
                                                <Typography>
                                                    {commentTranslation}
                                                </Typography>
                                            </TranslationInfoCard>
                                    )}
                            </>
                        )}
                    </Stack>
                </Grid>
            </Grid>

            <IgnoreDialog
                open={showIgnoreDialog}
                handleCloseDialog={() => setShowIgnoreDialog(false)}
                postId={postToComment._id}
                // fetchNextPostToComment={fetchNextPostToComment}
                fetchAfterIgnore={fetchNextPostWithoutCounterIncrement}
                // SOMA
                // setFullPostCount={setFullPostCount}
                decrementFullPostCount={decrementFullPostCount}
            />

            {/* nextbutton alert */}
            <AreYouSureDialog
                open={showNextAlertDialog}
                handleCloseDialog={() => setShowNextAlertDialog(false)}
                dialogueAlertText={
                    'You have an edited comment draft. You will lose your changes. Are you sure you want to continue?'
                }
                dialogAction={fetchNextPostToComment}
            />
            {/* previous button click alert */}
            <AreYouSureDialog
                open={showPrevAlertDialog}
                handleCloseDialog={() => setShowPrevAlertDialog(false)}
                dialogueAlertText={
                    'You have an edited comment draft. You will lose your changes. Are you sure you want to continue?'
                }
                dialogAction={fetchPreviousPostToComment}
            />

            {/* // / new button click alert  */}
            <AreYouSureDialog
                open={showNewAlertDialog}
                handleCloseDialog={() => setShowNewAlertDialog(false)}
                dialogueAlertText={
                    'You have an edited comment draft. You will lose your changes. Are you sure you want to continue?'
                }
                dialogAction={fetchCommentRecommendation}
            />
        </Container>
    );
};

const IgnoreDialog = ({
    open,
    handleCloseDialog,
    fetchAfterIgnore,
    postId,
    decrementFullPostCount,
}) => {
    const handleIgnore = async () => {
        try {
            await markPostAsIgnored(postId);
            await fetchAfterIgnore();
            await decrementFullPostCount();
            handleCloseDialog();
        } catch (err) {
            console.log('marking service not available');
        }
    };

    return (
        <Dialog open={open} onClose={handleCloseDialog}>
            <Paper
                sx={{
                    padding: '40px',
                    width: '300px',
                }}
            >
                <Typography variant="h6" paddingBottom={2}>
                    Are you sure you want to ignore this post?
                </Typography>
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    paddingX={2}
                    paddingY={1}
                    borderRadius={4}
                    marginBottom={2}
                >
                    <Button variant="contained" color="primary" onClick={handleIgnore}>
                        Ignore
                    </Button>

                    <Button variant="contained" color="primary" onClick={handleCloseDialog}>
                        Cancel
                    </Button>
                </Stack>
            </Paper>
        </Dialog>
    );
};

const AreYouSureDialog = ({ open, handleCloseDialog, dialogueAlertText, dialogAction }) => {
    return (
        <Dialog open={open} onClose={handleCloseDialog}>
            <Paper
                sx={{
                    padding: '40px',
                    width: '300px',
                }}
            >
                <Typography variant="h6" paddingBottom={2}>
                    {dialogueAlertText}
                </Typography>
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    paddingX={2}
                    paddingY={1}
                    borderRadius={4}
                    marginBottom={2}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            dialogAction();
                            handleCloseDialog();
                        }}
                    >
                        Continue
                    </Button>

                    <Button variant="contained" color="secondary" onClick={handleCloseDialog}>
                        Cancel
                    </Button>
                </Stack>
            </Paper>
        </Dialog>
    );
};

const CommentTypeSelector = ({
    commentTypeOptionObjectList,
    commentDict,
    chosenCommentType,
    setChosenCommentType,
}) => {
    const classes = useStyles();
    const [closed, setClosed] = useState(false);

    // handler functions
    const handleSetChosenType = (type) => {
        const chosenType = commentTypeOptionObjectList.find((obj) => obj.type === type);
        setChosenCommentType(chosenType);
    };

    const handleChange = (event) => {
        handleSetChosenType(event.target.value);
    };

    // render
    return (
        <Select
            displayEmpty
            value={chosenCommentType.type}
            defaultValue={commentTypeOptionObjectList[0].type}
            onChange={handleChange}
            onClick={(e) => {
                setClosed(false);
            }}
            // onFocus={(e) => {
            //     console.log('focus');
            // }}
            onBlur={(e) => {
                setClosed(false);
            }}
            onClose={(e) => {
                setClosed(true);
            }}
            inputProps={{ 'aria-label': 'Without label' }}
            className="customSelect"
            MenuProps={{
                TransitionComponent: Fade,
                transitionDuration: 500,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },

                PaperProps: {
                    style: {
                        borderRadius: '12px',
                        width: '300px',
                        backgroundColor: '#001E2B',
                        paddingBottom: '16px',
                    },
                },
            }}
            sx={{
                width: '100%',
                fontWeight: 'bold',
                border: 'none',
                outline: 'none',
                textDecoration: 'none',
                marginRight: '8px',
                '&:hover': {
                    outline: '2px solid #dbdbdc',
                },

                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                },
                '&.Mui-focused': {
                    backgroundColor: closed ? 'white' : '#dbdbdc',
                },
                // '&.Mui-focused:not:focus': {
                // '&:focusout.Mui-focused': {
                // '&.Mui-InputBase:visited': {
                // '&:not(.Mui-focused)': {}
                '& .MuiSelect-nativeInput': { border: '0px solid white' },
                '& .MuiSelect-select': { border: '0px solid white' },
                '& .MuiOutlinedInput-notchedOutline': {
                    border: '0px solid white',
                },
            }}
        >
            <Typography
                sx={{
                    padding: '8px 16px 16px 16px',
                    color: 'white',
                    // center
                    textAlign: 'center',
                    // if no comments for this type, show red
                }}
            >
                Comment Type
            </Typography>
            {/* <MenuItem value="" className={classes.customItem}> <em>None</em> </MenuItem> */}
            {commentTypeOptionObjectList.reduce((acc, option, index) => {
                if (commentDict[option.type] === 0) {
                    return acc;
                }
                acc.push(
                    <MenuItem
                        key={option.type}
                        value={option.type}
                        className={classes.customItem}
                        sx={{
                            paddingLeft: '32px',
                            '&:hover': {
                                backgroundColor: '#1C2D38',
                            },
                            '&.Mui-selected': {
                                backgroundColor: '#001E2B',
                            },
                            '&.Mui-selected:hover': {
                                backgroundColor: '#1C2D38',
                            },
                            color: commentDict[option.type] === 0 ? 'gray' : 'white',
                            textTransform: 'uppercase',
                            fontWeight: '500',
                            fontSize: '12px',
                        }}
                        onClick={(e) => {
                            handleSetChosenType(option.type);
                            setClosed(true);
                        }}
                    >
                        {option.label}
                    </MenuItem>
                );

                return acc;
            }, [])}
        </Select>
    );
};

const NoPostsAtAll = () => {
    return (
        <Box marginLeft="10vw" marginTop="25vh">
            <Typography
                variant="h2"
                component="h1"
                sx={{
                    marginBottom: '16px',
                }}
            >
                No posts to comment for you
            </Typography>

            <Typography>Please contact the Wineconx team for assistance.</Typography>
        </Box>
    );
};

const NoPostsForCommentType = ({ commentTypeOptions, fullPostCountDict, setChosenCommentType }) => {
    return (
        <Box marginLeft="10vw" marginTop="25vh">
            <Typography
                variant="h2"
                component="h1"
                sx={{
                    marginBottom: '16px',
                }}
            >
                There are no posts to comment for this type of comments
            </Typography>

            <Typography>Please contact the Wineconx team for assistance.</Typography>

            <Button
                sx={{
                    marginTop: '24px',
                }}
                variant="contained"
                onClick={() => {
                    const firstTypeWithPosts = commentTypeOptions.find(
                        (type) => fullPostCountDict[type.type] > 0
                    );

                    setChosenCommentType(firstTypeWithPosts);
                }}
            >
                Go back
            </Button>
        </Box>
    );
};

function CaptionTranslationTabs({ postToComment }) {
    const indexOfTabWithPost = 0;
    const indexOfTabWithTranslation = 1;
    const [captionTranslationTabsValue, setCaptionTranslationTabsValue] =
        useState(indexOfTabWithPost);

    const handleTranslationTabChange = (event, newValue) => {
        setCaptionTranslationTabsValue(newValue);
    };

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </div>
        );
    }

    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <Box sx={{ width: '90%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={captionTranslationTabsValue}
                    onChange={handleTranslationTabChange}
                    aria-label="basic tabs example"
                >
                    <Tab label="Post" {...a11yProps(indexOfTabWithPost)} />
                    <Tab label="Translation" {...a11yProps(indexOfTabWithTranslation)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={captionTranslationTabsValue} index={indexOfTabWithPost}>
                <Typography variant="body2" whiteSpace="pre-wrap" paddingRight={2}>
                    {/* replace non breakable spaces */}
                    {postToComment.mediaToCaption}
                </Typography>
            </CustomTabPanel>
            <CustomTabPanel value={captionTranslationTabsValue} index={indexOfTabWithTranslation}>
                <Typography variant="body2" whiteSpace="pre-wrap" paddingRight={2}>
                    {/* replace non breakable spaces */}
                    {postToComment.englishTranslation}
                </Typography>
            </CustomTabPanel>
        </Box>
    );
}

export default AppFrame(CommentInstagramTool);
